import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de_DE from './translations/de.json';
import en_US from './translations/en.json';
import es_ES from './translations/es.json';
import fr_FR from './translations/fr.json';
import it_IT from './translations/it.json';
import ja_JA from './translations/ja.json';

export const translations = {
  de: de_DE,
  en: en_US,
  it: it_IT,
  es: es_ES,
  fr: fr_FR,
  jp: ja_JA,
};

export const initializeLocales = (lang = 'en') => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        de: {
          translation: de_DE,
        },
        en: {
          translation: en_US,
        },
        it: {
          translation: it_IT,
        },
        es: {
          translation: es_ES,
        },
        fr: {
          translation: fr_FR,
        },
        jp: {
          translation: ja_JA,
        },
      },

      keySeparator: false,

      detection: {
        order: ['localStorage', 'navigator'],
      },

      fallbackLng: 'en',

      interpolation: {
        escapeValue: false,
      },
    });
};

export default i18n;
