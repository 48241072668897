import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFilters } from '../../store/hooks/filters';
import { useUsers } from '../../store/hooks/company';

import { searchText } from '../../components/Common/Filters/TextFilter';
import DataTable from '../../components/Common/DataTable/Page';
import StatusChip from '../../components/Common/StatusChip';
import TextFilter from '../../components/Common/Filters/TextFilter';
import RoleFilter from '../../components/Users/Filters/RoleFilter';
import StatusFilter from '../../components/Users/Filters/StatusFilter';
import InviteUsersForm from '../../components/Users/InviteUsersForm';

const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

const UserListPage = () => {
  const { t } = useTranslation();
  const { users, inviteUser, requests } = useUsers();

  const filters = useFilters({
    name: {
      chip: value => `${t('Name')}: ${value}`,
      component: (currentFilters, setFilter) => (
        <TextFilter label={t('Name')} field="name" currentFilters={currentFilters} setFilter={setFilter} />
      ),
    },
    email: {
      chip: value => `${t('Email')}: ${value}`,
      component: (currentFilters, setFilter) => (
        <TextFilter label={t('Email')} field="email" currentFilters={currentFilters} setFilter={setFilter} />
      ),
    },
    role: {
      chip: value => `${t('Role')}: ${value}`,
      component: (currentFilters, setFilter) => <RoleFilter currentFilters={currentFilters} setFilter={setFilter} />,
    },
    status: {
      chip: value => `${t('Status')}: ${value}`,
      component: (currentFilters, setFilter) => <StatusFilter currentFilters={currentFilters} setFilter={setFilter} />,
    },
  });

  const executeFilters = items => {
    let filtered = [...items];
    if ('name' in filters.current) {
      filtered = filtered.filter(item => searchText(item.name, filters.current.name));
    }
    if ('email' in filters.current) {
      filtered = filtered.filter(item => searchText(item.email, filters.current.email));
    }
    if ('role' in filters.current) {
      filtered = filtered.filter(item => item.partner_role === filters.current.role);
    }
    if ('status' in filters.current) {
      filtered = filtered.filter(item => item.state === filters.current.status);
    }
    return filtered;
  };

  const getActions = () => <InviteUsersForm onSubmit={inviteUser} isLoading={requests.create.loading} />;

  return (
    <DataTable
      page={{ title: t('Users'), actions: getActions, subtitle: t('Users with access permission to this company') }}
      items={users}
      columns={{
        name: { label: t('Name') },
        email: { label: t('Email') },
        partner_role: { label: t('Role'), render: value => capitalize(value) },
        state: {
          label: t('Status'),
          render: value => <StatusChip status={capitalize(value)} />,
        },
      }}
      filters={{ ...filters, execute: executeFilters }}
      detailLink={item => `/profile/${item.id}`}
    />
  );
};

export default React.memo(UserListPage);
